import React from 'react';
import {useLocation} from 'react-router-dom';
import {Download16} from '@carbon/icons-react';
import FileSaver from 'file-saver';

import {HeaderContainer, Header, Image, ViewResumeLink} from './styles';

const UserHeader = ({user}) => {    

    const location = useLocation();

    function handleClick() {
        FileSaver.saveAs('./resume.pdf', 'resume.pdf');
    }

    return (
        <HeaderContainer isHome={location.pathname !== '/'} >
            <Header>
                <Image src={user.basics.picture} />
                <div>
                    <h2>{user.basics.name}</h2>
                    <h4>
                        <a
                            href={`https://gitconnected.com/${user.basics.username}`}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            @{user.basics.username}
                        </a>
                    </h4>
                    <p>{user.basics.label}</p>
                    <p>Coding in {user.basics.region}</p>
                    <p>{user.basics.yearsOfExperience} years of experience as a developer</p>
                    <p>{user.basics.headline}</p>                    
                </div>
            </Header>
            <div>
                <ViewResumeLink
                    // href={`https://gitconnected.com/${user.basics.username}/resume`}
                    // target="_blank"
                    // rel= "noopner noreferrer"
                    onClick={handleClick}
                >
                    <span>Download Resume</span>
                    <Download16/>
                </ViewResumeLink>
            </div>
        </HeaderContainer>
    )
}

export default UserHeader